body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

progress[value] {
    border-radius: var(--border-radius);
}
progress[value]::-webkit-progress-bar {
    background-color: transparent;
}
progress[value]::-webkit-progress-value {
    background-color: currentColor;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

input.no-numbers::-webkit-outer-spin-button,
input.no-numbers::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

input.no-numbers {
    -moz-appearance: textfield;
}

.text-right {
    text-align: right;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
